export const formatDate = (dateString) => {
  if (!dateString) return "/";
  const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
  return new Date(dateString).toLocaleDateString('en-CA', options);
};

export const developmentData = {
  "firstName": "John",
  "lastName": "Doe",
  "accounts": [
    {
      "id": "qwr6MeZ7waAr",
      "email": "info@johndoe.com",
      "first_name": "John",
      "last_name": "Doe",
      "roles": [],
      "subscription": "2025-07-03T00:00:00.000Z",
      "devices": [
        {
          "id": "jgKWjpYA8v",
          "device": "hyper",
          "serial": 30009,
          "key": "6EAQ-QOL-VHW-GHC",
          "created_at": "2020-09-24T15:27:02.000Z",
          "updated_at": "2020-09-24T16:21:43.000Z"
        },
        {
          "id": "PEA4b8ZBqa",
          "device": "oudieNG",
          "serial": 0,
          "key": "15-0022",
          "created_at": "2022-01-17T07:27:59.000Z",
          "updated_at": "2024-03-06T14:16:28.000Z"
        }
      ],
      "has_glider_in_hangar": false,
      "has_paraglider_in_hangar": true,
      "has_hangglider_in_hangar": false,
      "created_at": "2016-11-07T17:18:01.000Z",
      "updated_at": "2021-11-30T15:57:54.000Z"
    },
    {
      "id": "qwr6MeZ7waAr",
      "email": "info@johndoe.com",
      "first_name": "John",
      "last_name": "Doe",
      "roles": [],
      "created_at": "2016-11-07T17:18:01.000Z",
      "updated_at": "2021-11-30T15:57:54.000Z"
    },
    {
      "id": "qwr6MeZ7waAr",
      "email": "info@johndoe.com",
      "first_name": "John",
      "last_name": "Doe",
      "roles": [],
      "created_at": "2016-11-07T17:18:01.000Z",
      "updated_at": "2021-11-30T15:57:54.000Z"
    }
  ]
};