import React from 'react';
import './DeviceInfo.css';
import { formatDate } from './Utils';

const deviceNames = {
  "oudie1": "Oudie",
  "oudie2": "Oudie 2",
  "oudie3": "Oudie 3",
  "oudieIGC": "Oudie IGC",
  "blade": "Blade",
  "oudie4Basic": "Oudie 4 Basic",
  "oudie4": "Oudie 4 Full",
  "hyper": "Hyper",
  "oudie5Basic": "Oudie 5 XC",
  "oudie5": "Oudie 5 Pro",
  "oudieNG": "Oudie N Gliding",
  "oudieNF": "Oudie N Free Flight",
  "oudieNF+": "Oudie N Free Flight Plus",
  "oudieNIGC": "Oudie N IGC",
  "omniF+": "Omni F Plus",
  "LX8030": "LX8030",
  "LX8040": "LX8040",
  "LX9000": "LX9000",
  "LX9050": "LX9050",
  "LX9070": "LX9070"
};

const deviceName = (device) => {
  return deviceNames[device.device] || device.device;
};

const deviceSerial = (device) => {
  return device.serial !== 0 ? device.serial : device.key;
}

const deviceLink = (device) => {
  return `https://orders.naviter.com/devices?q=${deviceSerial(device)}`;
};

const DeviceInfo = ({ devices }) => {
  return (
    <div className="device-info">
      <ul className="device-list">
        {devices.map((device, index) => (
          <li key={index} className="device-item">
            <p className="device-detail meta"><strong>{deviceName(device)}</strong> <a href={deviceLink(device)} target="_blank" rel="noreferrer" className="email-value">{deviceSerial(device)}</a></p>
            <p className="device-detail meta"><strong>Created at:</strong> {formatDate(device.created_at)}</p>
            <p className="device-detail meta"><strong>Updated at:</strong> {formatDate(device.updated_at)}</p>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default DeviceInfo;
