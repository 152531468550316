import React, { useEffect, useRef, useState } from 'react';
import HelpScout from '@helpscout/javascript-sdk';
import UserInfo from './UserInfo';
import { developmentData } from './Utils';
import './App.css';

const App = () => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const appContainerRef = useRef(null);

  useEffect(() => {
    const appContainer = appContainerRef.current;
    const resizeObserver = new ResizeObserver(() => {
      HelpScout.setAppHeight(appContainer.clientHeight);
    });
    resizeObserver.observe(appContainer);

    const fetchData = async () => {
      try {
        const isDevelopment = process.env.REACT_APP_ENV === "development";
        if (isDevelopment) {
          const result = developmentData;
          setData(result);
          return;
        }
        const queryParams = new URLSearchParams(window.location.search);
        const response = await fetch(`https://api.seeyou.cloud/public/helpscout?${queryParams.toString()}`);
        if (!response.ok) {
          throw new Error('not authenticated');
        }
        const result = await response.json();
        setData(result);
      } catch (error) {
        setError(error);
      }
    };

    fetchData();
  }, []);

  return (
    <div ref={appContainerRef} style={{ "padding-left": "14px", "padding-bottom": "3px", "padding-top": "0.1px", "padding-right": "14px" }}>
      {error ? <p><center>{error.message}</center></p> : data ? <UserInfo data={data} /> : <p></p>}
    </div>
  );
};

export default App;
